/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCRLcvPbYDvbBrlGMn3HcKX6xQtw-QMtzs",
  "appId": "1:916672077225:web:3cf9c915063d461b22f8b8",
  "authDomain": "schooldog-i-lowndes-al.firebaseapp.com",
  "measurementId": "G-2WLTFSP0GD",
  "messagingSenderId": "916672077225",
  "project": "schooldog-i-lowndes-al",
  "projectId": "schooldog-i-lowndes-al",
  "showGaBranding": false,
  "storageBucket": "schooldog-i-lowndes-al.appspot.com"
}
